import React from 'react';
import { useQuery } from 'react-query';
import { LoadingContainer } from 'bright-components';
import getToken from 'utils/getToken';
import Avatar from '@brighthr/component-avatar';
import http from 'utils/http';
import config from 'utils/config';

const TokenClaims = ({ subject, issuer, clientId, email, picture }) => (
	<>
		<h2 className="mt-8 text-neutral-700">Token Claims:</h2>
		<div className="flex flex-col justify-between mb-10 border border-primary-100 rounded-2xl md:flex-row md:rounded-2xl">
			<div className="flex items-center p-4">
				<Avatar imageHref={picture} name={email} size="xl" />
				<div className="px-4">
					<div className="text-xl">{email}</div>
					<div className="mt-3 text-md">Subject: {subject}</div>
				</div>
			</div>
			<ol className="p-4 sm:pr-32 bg-primary-50 rounded-b-2xl md:rounded-bl-none md:rounded-r-2xl">
				<li className="flex font-medium text-md">OAuth Client ID</li>
				<ul className="flex-row mt-1 text-xs">{clientId}</ul>
				<li className="mt-5 font-medium text-md">Token Issuer</li>
				<ul className="flex-row mt-1 text-xs">{issuer}</ul>
			</ol>
		</div>
	</>
);

const ApiResult = ({ name, result }) => (
	<LoadingContainer loading={result?.isLoading} title="loading-spinner">
		<h2 className="mt-8 mb-2 text-neutral-700">{name}</h2>
		{result?.data && (
			<>
				<div>Result={result.data.status}</div>
				<div>{result.data.headers['x-azure-apim']}</div>
			</>
		)}
		{result?.error && <div>Result={result.error.response.status}</div>}
	</LoadingContainer>
);

const getInternalApiWithKey = () => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}company/healthz`;
	const options = {
		headers: { 'Ocp-Apim-Subscription-Key': 'a80046c6968546dfae08d00be62c1841' },
	};
	return http.get(uri, options).then((res) => res);
};

const getInternalApiWithoutKey = () => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}company/healthz`;
	return http.get(uri).then((res) => res);
};

const getPublicApiWithKey = () => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}employee/healthz`;
	const options = {
		headers: { 'Ocp-Apim-Subscription-Key': 'a80046c6968546dfae08d00be62c1841' },
	};
	return http.get(uri, options).then((res) => res);
};

const getPublicApiWithoutKey = () => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}employee/healthz`;
	return http.get(uri).then((res) => res);
};

export default () => {
	const { sub: subject, iss: issuer, client_id: clientId, email, picture } = getToken();

	const internalApiWithKey = useQuery(
		['getInternalApiWithKey', subject],
		() => getInternalApiWithKey(),
		{
			retry: false,
		}
	);
	const internalApiWithoutKey = useQuery(
		['getInternalApiWithoutKey', subject],
		() => getInternalApiWithoutKey(),
		{
			retry: false,
		}
	);
	const publicApiWithKey = useQuery(
		['getPublicApiWithKey', subject],
		() => getPublicApiWithKey(),
		{
			retry: false,
		}
	);
	const publicApiWithoutKey = useQuery(
		['getPublicApiWithoutKey', subject],
		() => getPublicApiWithoutKey(),
		{
			retry: false,
		}
	);

	return (
		<main className="justify-center max-w-screen-lg px-8 py-12 mx-auto md:p-4 lg:w-auto">
			<h1 className="my-4 text-neutral-700">Public Client Demo</h1>

			<TokenClaims
				subject={subject}
				issuer={issuer}
				clientId={clientId}
				email={email}
				picture={picture}
			/>

			<ApiResult
				name="/v1/company/healthz (internal - with key)"
				result={internalApiWithKey}
			/>

			<ApiResult
				name="/v1/company/healthz (internal - without key)"
				result={internalApiWithoutKey}
			/>

			<ApiResult name="/v1/employee/healthz (public - with key)" result={publicApiWithKey} />

			<ApiResult
				name="/v1/employee/healthz (public - without key)"
				result={publicApiWithoutKey}
			/>
		</main>
	);
};
