import getToken from 'utils/getToken';

function getConfigOverrides() {
	return JSON.parse(window.sessionStorage.getItem('config') || '{}');
}

const configBase = (env = 'prod') => {
	const { brighthr_app_region: country = 'UK' } = getToken();
	const getEnv = (config) => {
		const firstLevel = config[country.toLowerCase()] || config[env] || config.default;

		return firstLevel[env] || firstLevel.default || firstLevel;
	};

	return {
		GLOBAL_API: getEnv({
			default: 'https://sandbox-api.brighthr.com/v1/',
		}),

		AUTHORISATION_SERVER_URL: getEnv({
			dev: 'https://localhost:44301',
			default: 'https://sandbox-login.brighthr.com',
		}),
	};
};

export const set = (configOverrides) => {
	window.sessionStorage.setItem('config', JSON.stringify(configOverrides));
	window.location.reload();
};

export const clear = () => {
	window.sessionStorage.removeItem('config');
	window.location.reload();
};

export default (environment) => {
	return {
		...configBase(environment),
		...getConfigOverrides(),
	};
};
