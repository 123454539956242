import getConfig from './config';

const mapping = {
	localhost: 'dev',
};

const environment = mapping[window.location.hostname] || 'sandbox';

export default () => ({
	BUILD_VERSION: process.env.BUILD_SOURCEVERSION,
	environment,
	...getConfig(environment),
});
