import React from 'react';
import { Route } from 'react-router-dom';

const AuthPlaceholderRoute = () => null;

const Routes = [
	// Needed due to auth hook - return null
	<Route exact path="/login" component={AuthPlaceholderRoute} />,
	<Route exact path="/logout" component={AuthPlaceholderRoute} />,
	<Route path="/login/callback" component={AuthPlaceholderRoute} />,
];

export default Routes;
