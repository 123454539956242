import config from 'utils/config';

function getHeaders() {
	const token = window.localStorage.getItem('userToken');
	const { BUILD_VERSION: BuildVersion } = config();

	return {
		...(token && { Authorization: `bearer ${token}` }),
		...(BuildVersion && { BuildVersion }),
	};
}

export default getHeaders;
