import axios from 'axios';
import hasValidUserToken from 'utils/auth/isLoggedIn';
import hasValidRefreshToken from 'utils/auth/hasValidRefreshToken';
import { refreshToken } from 'hooks/useAuthentication';
import getHeaders from './getHeaders';

const urlWhitelist = [];

const isWhitelistedUrl = (url) =>
	urlWhitelist.some((rule) => {
		if (rule instanceof RegExp) {
			return rule.test(url);
		}
		const resource = url.split('/').pop();
		return resource === rule;
	});

let tokenRefreshing = false;
let promisesAwaitingValidToken = [];

function baseRequest(url, options = {}) {
	const { headers = {}, method, ...opts } = options;

	const makeAPICall = () => {
		return axios(url, {
			method: method || 'get',
			headers: { ...getHeaders(), ...headers },
			...opts,
		});
	};

	if (!isWhitelistedUrl(url) && !hasValidUserToken()) {
		if (!hasValidRefreshToken()) {
			if (window.location.pathname !== '/logout') {
				sessionStorage.setItem('redirectUrl', window.location.pathname);
				window.location.assign('/logout');
			}

			// this prevents http calls from resolving and causing misleading error reports
			//  it may also cause memory leaks
			return new Promise(() => {});
		}

		if (!tokenRefreshing) {
			tokenRefreshing = true;

			refreshToken().then(() => {
				tokenRefreshing = false;
				promisesAwaitingValidToken.forEach(({ resolveMethod, apiCall }) => {
					resolveMethod(apiCall());
				});
				promisesAwaitingValidToken = [];
			});
		}
	}

	if (tokenRefreshing) {
		return new Promise((resolve) => {
			promisesAwaitingValidToken.push({
				resolveMethod: resolve,
				apiCall: makeAPICall,
			});
		});
	}

	return makeAPICall();
}

export default baseRequest;
