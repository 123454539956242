import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthenticatedRoutes from './routing/Authenticated';
import PublicRoutes from './routing/Public';
import useAuthentication from './hooks/useAuthentication';

const queryClient = new QueryClient();

function App() {
	const { isAuthenticated } = useAuthentication();
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Switch>
					{isAuthenticated && AuthenticatedRoutes}
					{PublicRoutes}
				</Switch>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
